import { Logo } from "../../assests";
import "./loader.css";

function Loader() {
  return (
    <div className="loader_conta">
      <img
        className="loader_img"
        src={Logo}
        width={"200px"}
        height={"200px"}
      ></img>
    </div>
  );
}

export default Loader;
